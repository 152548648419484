@tailwind base;
@tailwind components;
@tailwind utilities;
footer {
    position: relative;
    z-index: 10;
  }
  @media (min-width: 780px) and (max-width: 1020px) {
    .services-adjustment {
      scroll-margin-top: 33px; /* Postavi na 0 ili na vrlo mali broj da bi naslov bio odmah ispod headera */
      padding-top: 60px; /* Dodaj padding da se sadržaj ne sudara sa headerom */
    }
  }